/*
_atlas-glyphs.scssにvertical-align:middle;が追記されるため上書きしています。

<i class="atlas-glyphs-action"></i>
<i class="atlas-glyphs-archive"></i>
<i class="atlas-glyphs-arrow-down"></i>
<i class="atlas-glyphs-arrow-left"></i>
<i class="atlas-glyphs-arrow-right"></i>
<i class="atlas-glyphs-arrow-up"></i>
<i class="atlas-glyphs-attachment"></i>
<i class="atlas-glyphs-batsu"></i>
<i class="atlas-glyphs-blocked"></i>
<i class="atlas-glyphs-calender"></i>
<i class="atlas-glyphs-camera"></i>
<i class="atlas-glyphs-community"></i>
<i class="atlas-glyphs-double-arrow-down"></i>
<i class="atlas-glyphs-double-arrow-left"></i>
<i class="atlas-glyphs-double-arrow-right"></i>
<i class="atlas-glyphs-double-arrow-up"></i>
<i class="atlas-glyphs-external-link"></i>
<i class="atlas-glyphs-eye"></i>
<i class="atlas-glyphs-file-doc"></i>
<i class="atlas-glyphs-file-pdf"></i>
<i class="atlas-glyphs-file-ppt"></i>
<i class="atlas-glyphs-file-xls"></i>
<i class="atlas-glyphs-list"></i>
<i class="atlas-glyphs-loading"></i>
<i class="atlas-glyphs-mail-read"></i>
<i class="atlas-glyphs-mail"></i>
<i class="atlas-glyphs-maru"></i>
<i class="atlas-glyphs-page-left"></i>
<i class="atlas-glyphs-page-right"></i>
<i class="atlas-glyphs-pencil"></i>
<i class="atlas-glyphs-print"></i>
<i class="atlas-glyphs-reply"></i>
<i class="atlas-glyphs-search"></i>
<i class="atlas-glyphs-service-campaign"></i>
<i class="atlas-glyphs-service-clinical"></i>
<i class="atlas-glyphs-service-conference"></i>
<i class="atlas-glyphs-service-edetail"></i>
<i class="atlas-glyphs-service-mrkun"></i>
<i class="atlas-glyphs-service-quiz"></i>
<i class="atlas-glyphs-service-survey"></i>
<i class="atlas-glyphs-service-web"></i>
<i class="atlas-glyphs-setting"></i>
<i class="atlas-glyphs-speaker-off"></i>
<i class="atlas-glyphs-speaker-on"></i>
<i class="atlas-glyphs-star-blank"></i>
<i class="atlas-glyphs-star"></i>
<i class="atlas-glyphs-telephone"></i>
<i class="atlas-glyphs-zoom-in"></i>
<i class="atlas-glyphs-zoom-out"></i>
*/

[class^="atlas-glyphs-"]:before,
[class*=" atlas-glyphs-"]:before,
[class^="atlas-glyphs-"],
[class*=" atlas-glyphs-"] {
  vertical-align: baseline;
  text-decoration: none !important;
}

.atlas-glyphs-action {
  display: inline-block;
  line-height: 1; 
  background: #fff;
  border-radius: $base-border-radius;
  color: #ca0814;
}

.atlas-glyphs-external-link--light {
  color: #fff;
}