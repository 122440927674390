// Base
// -------------------------
$base-font:          "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック",  "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", sans-serif;
$base-font-size:     0.875rem; // 14px
$base-font-color:    #555;
$base-line-height:   1.5;
$base-color:         #333;
$link-color:         #03a;
$visited-color:      #775cad;
$base-border-color:  #dedede;
$base-border-radius: 4px;


// Key Color Scheme
// -------------------------
$default-color:   #dcdcdc;
$primary-color:   #40509e;
$secondary-color: #e5e8f0;
$success-color:   #36a763;
$info-color:      #d5dbe7;
$warning-color:   #e89b4a;
$danger-color:    #c81f3d;


// Text
// -------------------------
$text-important-color:            #c00;
$text-primary-color:              $link-color;
$text-secondary-color:            #999;
$text-success-color:              #4cae4c;
$text-warning-color:              #e89b4a;
$text-danger-color:               #c81f3d;
$text-muted-color:                #ddd;


// Category Labels
// -------------------------
$label-bg-01:   #8c5e9d;
$label-bg-02:   #9e4d72;
$label-bg-03:   #46a09b;
$label-bg-04:   #d37c4d;
$label-bg-05:   #6d799c;
$label-bg-06:   #4b9ab8;
$label-bg-07:   #5d905d;
$label-bg-08:   #c77171;
$label-bg-09:   #d89e00;
$label-bg-10:   #665fc4;
$label-bg-11:   #8fad47;
$label-bg-12:   #d77cc0;
$label-bg-wic:  #2d0097;


// My MRkun List
// -------------------------
$my-mrkun-color:      $primary-color;
$my-mrkun-bg-color:   #f2f6ff;


// Ranking List
// -------------------------
$ranking-color: #7c95db;
$ranking-color-1: #d0a32f;
$ranking-color-2: #b9b9b0;
$ranking-color-3: #d07149;

// V3 Color Scheme
// -------------------------
$border-color: #e5e7eb;

// v3　リニューアルヘッダー用 ------------------------------------------------------------
$v3-base-font-color: #3b3d42;
$v3-primary-gray: #9ea2aa;
$v3-border-color-gray: #e5e7eb;
$v3-icon-blue-01: #3b46c4;
