%infobox {
  width: 240px;
  font-size: $base-font-size;
  background-color: #40509e;
  @include box-shadow(rgba(0,0,0,0.3) 2px 2px 10px);
}

.atlas-header__infobox {
  @extend %infobox;
  position: absolute;
  top: 40px;
  right: -10px;
  z-index: 8002; //ヘッダーパーツとして調整
  @include dropdown-animation-hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 7px;
    top: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 9px 7.5px;
    border-color: transparent transparent #40509e transparent;
  }
}

.m3-twbs-open {
  & > .atlas-header__infobox {
    @include dropdown-animation-show;
  }
}

.atlas-infobox {
  @extend %infobox;
  display: none;
  position: fixed;
  width: 240px;
  right: 48px;
  color: $base-font-color;
  font-family: $base-font;
  background: #40509e;
}

.atlas-infobox__info {
  margin: 0;
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
   margin: 0;
   padding: 0;
  }

  dt {
    margin: 0;
    padding: 10px;
    color: #fff;
    font-weight: bold;
  }

  dd {
    margin: 0;
    padding: 0;
    border-top: 1px solid #7d86be;
    font-size: rem-calc(12);
    &:first-of-type {
      border-top: none;
    }

    a {
      display: block;
      padding: 8px 10px;
      background-color: #6671b3;
      text-decoration: none;
      color: #fff;

      &:hover {
        background-color: #7d86be;
      }
    }
  }
}

.atlas-infobox__premium__title {
  display: block;
  margin-bottom: 3px;
  font-weight: bold;
}

.atlas-infobox__point {
  span {
    display: block
  }
}

.atlas-infobox__point__text-02 {
  position: relative;
  padding-left: 23px;

  &:before {
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -9px;
    content: "";
    @include sprite($mark_m3point_small);
  }
}

.atlas-infobox__point__text-03 {
  position: relative;
  padding-left: 23px;

  &:before {
    display: block;
    width: 9px;
    height: 7px;
    position: absolute;
    left: 9px;
    top: 50%;
    margin-top: -4px;
    content: "└";
  }

  em {
    font-style: normal
  }
}

.atlas-infobox__function {
  border-top: 1px solid #6f79b8;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    border-top: 1px solid #6672b2;
    list-style: none;
    font-size: rem-calc(12);
    &:first-child {
      border-top: none;
    }
  }

  a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #fff;
  }
}

.atlas-infobox__close { //使用する際、×は.atlas-glyphs-batsuを使用すること
  overflow: hidden;
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  top: 10px;
}




// v3　リニューアルヘッダー用 ------------------------------------------------------------

.v3.atlas-header {

  .atlas-infobox {
    background: #E5E7EB;
    color:#3b3d42;
    transition: 0.1s;
    -webkit-transition: 0.1s;
  }

  .atlas-header__infobox {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 22px;
    width: 260px;
    left: auto;
    right: 0;
    top:38px;
  
    &:before {
      right: 9px;
      border-color: transparent transparent #FFFFFF transparent;
    }
  }

  .atlas-infobox__info {
    line-height: 1.4;
  
    dt {
      font-style: normal;
      font-size: 14px;
      padding: 12px 10px;
      a{
        font-weight: 600;
      }
    }
  
    dd {
      border-top: 1px solid #FFFFFF;
  
      a {
        background-color: #F6F6F6;
        font-weight: 400;
  
        &:hover {
          background-color: #E5E7EB;
          color:#6f737a;
        }
      }
    }
  }

  .atlas-infobox__function {
    border-top: 1px solid #FFFFFF;
    line-height: 1.4;
  
    li {
      border-top: 1px solid #F7F7F7;
      &:first-child {
        border-top: none;
      }
      &:last-child a:hover{
        border-radius: 0 0 8px 8px;
        }
      a {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        padding: 14px 10px;
        transition: 0.1s;
        &:hover {
          background-color: #F5F5F5;
          color:#6F737A;
          .m3-pull-right{
            left:0px;
          }
        }
        .m3-pull-right {
          position: relative;
          top:4px;
          left: -4px;
          width: 6px;
          height: 6px;
          border-top: 1px solid #9EA2AA;
          border-right: 1px solid #9EA2AA;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          transition: 0.1s;
          /* 新ヘッダーが旧ヘッダーと共通mustacheなため、暫定的にcss側で強制的に非表示。リニューアルが全面展開したらmustacheの方を矢印部分を削除しこちらも削除予定 */
          i{
            display: none;
          }
        }
      }
    }
  }


  // 検索ボックス用
  .atlas-header__infobox.searchbox {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 22px;
    width: 460px;
    left: 0;
    right: auto;
    top:40px;
  
    &:before {
      left: 20px;
      right: auto;
      border-color: transparent transparent #FFFFFF transparent;
    }

    h4 {
      font-size: 0.75rem;
      font-weight: 600;
      color: $v3-primary-gray;
      padding: 14px 20px;
      margin: 0;
    }

    ul {
      display: flex;
      flex-flow: wrap;
      margin: 0;
      padding: 0;
      border-top: 1px solid $v3-border-color-gray;
      li {
        width: 50%;
        margin: 0;
        padding: 0;
        border-top: none;
        border-bottom: 1px solid $v3-border-color-gray;
        &:nth-child(odd) {
          border-right: 1px solid $v3-border-color-gray;
        }
        &:last-child a:hover{
          border-radius: 0;
        }
        a {
          display: flex;
          align-items: center;
          height: 44px;
          padding: 0 20px;
          img {
            display: block;
            width: 14px;
            margin-right: 4px;
          }
          .atlas-glyphs-m-check {
            color: $v3-icon-blue-01;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
