/*
印刷物に個人情報が掲載される箇所を除外します。
*/
@media print {
  .atlas-header,
  .atlas-nav,
  .m3-breadcrumbs,
  .atlas-rhs,
  .atlas-footer,
  .atlas-infobox,
  .m3-button,
  .m3-function,
  .m3-custom-heading__link-list,
  .m3-custom-heading__link,
  .news-print {
    display: none !important;
  }

  .atlas-body {
    background-color: #fff;
    font-size: 14pt;
  }

  .atlas-main {
    width: 90%;
    margin: 0 auto;
  }
}
