.atlas-rhs {
  float: left;
  width: 230px;
  margin-left: 10px;
  font-size: $base-font-size;
  font-family: $base-font;
  color: $base-color;

  a {
    color: $link-color;
    text-decoration: none;

    &:visited {
      color: $visited-color;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

// ----- rhs note -----
.atlas-rhs__note {
  font-size: rem-calc(12);
  padding: 10px;
  color: #888;
  border-top: 1px solid #cccccc;

  p {
    margin: 0;
    padding: 0;
  }
}

.atlas-rhs__note-link {
  margin-right: 4px;
}

// ----- rhs box -----
.atlas-rhs__panel {
  border: 1px solid $base-border-color;
  margin-top: 10px;
  background-color: #e9e9e9;
}

// ----- rhs header -----
.atlas-rhs__panel__header {
  background-color: $primary-color;
  margin-left: -1px;
  margin-right: -1px;
}

.atlas-rhs__panel__header-title {
  color: #fff;
  font-size: $base-font-size;
  font-weight: normal;
  margin: 0;
  padding: 4px 10px;

  a {
    text-decoration: none !important;
    color: #fff !important;
  }
}

.atlas-rhs__panel__header--important {
  background-color: #f29a18;
}

.atlas-rhs__panel__header-subtitle {
  padding: 10px 2% 2px;
  margin: 0;
  font-weight: bold;
  background-color: #fff;
}

// ----- rhs sponsors notice -----
.atlas-rhs__notice {
  background-color: #ff0000;
  padding: 3px;
  margin: 10px 10px 0;

  ul {
    background-color: #ffc;
  }

  li:first-child {
    border-top: 0;
  }
}

.atlas-rhs__notice-title {
  margin: 0;
  color: #fff;

  a {
    color: #fff;

    &:visited {
      color: #fff;
    }
  }
}

// ----- rhs sponsors label -----

.sponsors-label--regular {
  display: none;
}

// ----- rhs article list -----
.atlas-rhs__article-list {
  margin: 0;
  padding: 0;
  background-color: #fff;

  li {
    @include clearfix;
    list-style-type: none;
    padding: 10px 2%;
    margin: 0;
    width: 96%;
    font-size: rem-calc(14);
    border-top: 1px solid #e5e5e5;

    &:before {
      margin-top: none
    }

    &:first-child {
      border-top: none;
    }
  }

  a {
    color: $link-color;
    text-decoration: none;
  }
}

.atlas-rhs-article-list-MyMr {
  background-color: $my-mrkun-bg-color;
}

.atlas-rhs-article-list-MyMrQuick {
  background-color: $my-mrkun-bg-color;
}

.atlas-rhs-article-list-MyMsl {
}

.atlas-rhs-article-list-M3Mr {
  background-color: #fff;
}

.atlas-rhs-article-list-Opd {
  background-color: #fffdf2;
}

.atlas-rhs__article-list__img {
  float: left;
  margin-right: 10px;
}

.atlas-rhs__article-list__img__thumbnail {
  display: block;
}

.atlas-rhs__frame {
  border: 1px solid $primary-color;
}

.atlas-rhs__frame-mr {
  border: 1px solid $base-border-color;
}

.atlas-rhs__frame-mymr {
  border: 1px solid $my-mrkun-color;
}

.atlas-rhs__frame-mymrquick {
  border: 1px solid $my-mrkun-color;
}

.atlas-rhs__frame-mymsl {
  border: 1px solid $base-border-color;
}

.atlas-rhs__frame-mr__title {
  background-color: $my-mrkun-color;
  color: #fff;
  font-size: rem-calc(10);
  text-align: center;
  letter-spacing: 0;
  line-height: 1.2;
}

.atlas-rhs__frame-mymr__title {
  background-color: $my-mrkun-color;
  color: #fff;
  font-size: rem-calc(10);
  text-align: center;
  text-indent: -0.125rem;
  letter-spacing: -.05rem;
  line-height: 1.2;
}

.atlas-rhs__frame-opd__title {
  background-color: $my-mrkun-color;
  color: #fff;
  font-size: rem-calc(10);
  text-align: center;
  text-indent: -0.0625rem;
  letter-spacing: -.21rem;
  line-height: 1.2;
}

.atlas-rhs__frame-mymrquick__title {
  background-color: $my-mrkun-color;
  color: #fff;
  font-size: rem-calc(10);
  text-align: center;
  letter-spacing: -.05rem;
  line-height: 1.2;
}

.atlas-rhs__frame-mymsl__title {
  background-color: $my-mrkun-color;
  color: #fff;
  font-size: rem-calc(10);
  text-align: center;
  letter-spacing: -.05rem;
  line-height: 1.2;
}

.atlas-rhs__article-list__text {
  margin-left: 50px;
}

.atlas-rhs__article-list__title {
  font-weight: bold;
}

.atlas-rhs__article-list__source {
  font-size: rem-calc(12);
  color: #666;
  display: block;
}

// ----- rhs article list sponsors -----
.atlas-rhs__article-list__sponsor {
  margin-right: 6px;
}

.atlas-rhs__article-list--ranking {
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 0;
  margin-top: 0;

  li:last-child {
    border-bottom: 0;
  }
}

// ----- rhs article list (large) -----
.atlas-rhs__article-list--large {
  .atlas-rhs__article-list__img {
    width: 60px;
    text-align: center;
  }

  .atlas-rhs__article-list__text {
    margin-left: 70px;
  }
}

// ----- rhs article list (small) -----
.atlas-rhs__article-list--small {
  .atlas-rhs__article-list__img {
    width: 40px;
  }
}

// ----- rhs article list -----
.atlas-rhs__article-tab {
  background-color: #e9e9e9;
  padding: 10px 10px 0 10px;

  .m3-twbs-nav-tabs {
    margin-top: 0;
  }

  .m3-twbs-tab-content {
    margin: 0 -10px;
  }

  .m3-twbs-dropdown-menu {
    width: 220px;
    left: auto;
    right: 0;
    li {
      width: 110px;
      float: left;
    }
  }

  .m3-article-list--ranking {
    background-color: #fff;
  }
}

// ----- rhs event -----
.atlas-rhs__article-list-event__service-name {
  color: #666;
  font-weight: bold;
  font-size: rem-calc(12);
}

@media screen and (min-width: 1280px) { //1270_RHSのMR君件名表示文字数最適化#199243でwidth:350pxで挙動の切り替え
  .atlas-rhs {
    width: 380px;
    margin-left: 20px;
  }
}

// ----- PDCA 1043 -----
.atlas-rhs__article-list__ranking {
  clear: both;
}

.atlas-rhs__article-list__ranking-best1 {
  background-image: url(https://s.m3img.com/m3-style/1.0.0/images/common/sprite.png);
  white-space: nowrap;
  overflow: hidden;
  text-indent: 100%;
  background-position: 0px 0px;
  width: 23px;
  height: 28px;
  float:left;
}

.atlas-rhs__article-list__ranking-best2 {
  background-image: url(https://s.m3img.com/m3-style/1.0.0/images/common/sprite.png);
  white-space: nowrap;
  overflow: hidden;
  text-indent: 100%;
  background-position: -43px -96px;
  width: 23px;
  height: 28px;
  float:left;
}

.atlas-rhs__article-list__ranking-best3 {
  background-image: url(https://s.m3img.com/m3-style/1.0.0/images/common/sprite.png);
  white-space: nowrap;
  overflow: hidden;
  text-indent: 100%;
  background-position: -43px 0px;
  width: 23px;
  height: 28px;
  float:left;
}

.atlas-rhs__article-list__ranking-best1__txt {
  float:left;
  font-size: 12px;
  color: #c00;
  font-weight: bold;
  vertical-align: middle;
  padding-left: 5px;
  padding-top:0.3em;
}

.atlas-rhs__article-list__ranking-best2__txt, .atlas-rhs__article-list__ranking-best3__txt {
  float:left;
  font-size: 12px;
  color: #ec7722;
  font-weight: bold;
  vertical-align: middle;
  padding-left: 5px;
  padding-top:0.3em;
}

// ----- PDCA 1120 - redmine #168504 -----
.atlas-rhs__article-list__unit {
  position:relative;
  cursor:pointer;

  .webcon-list-normal {
    border-radius: 2px;
    overflow: hidden;
    img {
      width: 45px;
      height: 60px;
      transition: all 0.3s;
    }
  }

  &:hover {
    background-color:#fffdf2;
    .atlas-rhs__article-list__title {
      text-decoration: underline;
      color: #6f737a;
    }
    .atlas-rhs__article-list__img__thumbnail {
      overflow: hidden;
      img {
        transform: scale(1.1);
      }
    }
    .webcon-list-normal {
      width: 45px;
      height: 60px;
      border-radius: 2px;
      overflow: hidden;
      img {
        transform: scale(1.1);
      }
    }
  }
  & > a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
  }
}

// ----- PDCA 1236 - https://redmine.m3.com/issues/208441 -----
.atlas-rhs__article-list__ranking__label {
  display:none;
  float:left;
  margin-top: 3px;
  span {
    display: inline-block;
    max-width: 100px;
    margin-left: 4px;
    padding: 2px 4px;
    overflow: hidden;
    box-sizing: border-box;
    background: #fff;
    font-weight: bold;
    font-size: 10px;
    font-size: 0.625rem;
    line-height: 1.2;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: -4px;
  }
  @at-root {
    #{&}_disease {
      border: 1px solid #5d905d;
      color: #5d905d;
    }
  }
}
@media screen and (min-width: 1280px) {
  .atlas-rhs__article-list__ranking__label {
    display:block;
  }
}

// ----- PDCA V128 - https://redmine.m3.com/issues/217483 -----
.mrq-mr-contact.m3-custom-heading {
  margin-top:0;

  & .m3-custom-heading__link {
    margin:2px 4px 2px 0;

    & .m3-button {
      padding:1px 8px;
      text-decoration:none;
    }

    & .m3-button--white {
      color: #333;
      background-color: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0);
      border-bottom: 3px solid #d9d9d9;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      border-left: 1px solid #e0e0e0;

      &:visited {
        color: #333;
      }
      &:hover {
        background-color: white;
        border-bottom: 3px solid #e6e5e5;
        text-decoration: none;
      }
      &:active {
        margin-top: 3px;
        border-bottom: none;
      }
    }
  }
}

// ----- PDCA 1512 - redmine #269464 -----
.atlas-rhs-article-list__description-tag {
  padding-bottom:32px!important;

  &__area {
    position:absolute;
    left:0;
    bottom:0;
    width:100%;
    padding:6px;
    box-sizing:border-box;
  }

  &__text {
    display:inline-block;
    position: relative;
    max-width:calc(100% - 10px);
    margin:0 0 0 10px;
    padding:0;
    background:#ec7722;
    line-height:20px;
    box-sizing:border-box;

    &:before {
      position: absolute;
      content:'';
      top: 0;
      left: -10px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 10px 10px 10px 0px;
      border-color: transparent #ec7722;
    }

    &  p {
      display:inline-block;
      width:100%;
      margin:0;
      padding:0 6px 0 0;
      color:#fff;
      font-size:12px;
      line-height:20px;
      vertical-align:bottom;
      box-sizing:border-box;
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;

      &:before {
        content:'●';
        font-size:10px;
        padding-right:0.5em;
      }
    }
  }
}

/////// RHS v3
// v3リニューアルデザイン用設定。
// 既存の設定に影響を与えないため、v3クラスを指定する。

$v3_rhs_black_color: #0B0C0D;
$v3_rhs_gray_color: #6F737A;
$v3_rhs_red_color: #DB1E1C; // 獲得アクション数などの赤色
$v3_rhs_light_blue_color: #0284C7; // 未読の青ポッチや、未登録、未回答などのステータスラベルの水色
$v3_rhs_gold_color: rgb(224, 161, 0); // 人気BEST1などのゴールド
$v3_rhs_silver_color: rgb(158, 162, 170); // 人気BEST2などのシルバー
$v3_rhs_bronze_color: rgb(176, 83, 21); // 人気BEST3のブロンズ

$v3_rhs_border_gray: #eeeff2; // 写真の枠線
$v3_rhs_list_title_color: #3b3d42; //記事一覧の黒
$v3_rhs_category_title_color: #000066; // リンクの色
$v3_rhs_category_title_color_hover: #151b9e; // リンクのhover時

// v3 cssを適用するかどうかは、rhsトップ要素のv3 classで判断する。
// NOTE: v3用のcssは、既存RHS(v2)のデザインには影響を与えないが、逆はある。
//       RHS v3は、できるだけRHS v2のHTML構造を維持しているため、既存RHSのcssを消す際は、v3への影響がないか確認が必要。
//       v3への完全移行時に、cssも完全移行したい。

// RHS v3全体
.v3.atlas-rhs {
  font-size: 14px;
  font-weight: 500;
  color: $v3_rhs_black_color;

  // CAの四隅を丸くする
  .m3_ca-container > div {
    border-radius: 4px;
  }

  // 「my MR君」などの途中で改行されたくないときにこのclassを使う
  .no-new-line {
    display: inline-block;
  }
}

// RHS v3パネル内
.v3.atlas-rhs .atlas-rhs__panel {
  background-color: #ffffff;
  border-radius: 4px;
  border: none;

  a {
    text-decoration: none !important;
  }

  // ステータスラベル
  .m3-status-label {
    border: none;
    color: rgb(2, 132, 199);
    background-color: unset;
    font-size: 12px;
    padding: 0;
  }
  .m3-status-label.border {
    border: 1px;
  }

  // RHSパネルのヘッダー
  .atlas-rhs__panel__header {
    background-color: unset;

    margin: 0 !important; // 既存のCSSでmargin-bottom 10px !importantの設定があるため!importantで打ち消す
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    // ヘッダーのタイトル
    .atlas-rhs__panel__header-title {
      background-color: unset;
      width: 100%;

      margin-left: 0;
      margin-right: auto;
      padding: 0 15px;

      font-size: 13px;
      font-weight: 700;
      line-height: 1;
      color: #000066;

      a {
        display: flex;
        align-items: center;
        color: $v3_rhs_category_title_color !important;
        padding: 15px 0;
        &:hover {
          color: $v3_rhs_category_title_color_hover !important;
          &::after {
            margin-left: 12px;
          }
        }
      }
      // リンクテキスト右の>記号の画像
      a::after {
        content: "";
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/glyphs/arrow-right.svg);
        width: 16px;
        height: 16px;
        margin-left: 4px;
        transition: all 0.15s;
      }
    }
    // MR君・医療情報メッセージ：幅狭用
    &.mrq-mr-contact {
      @media screen and (max-width: 1279px) {
        .atlas-rhs__panel__header-title {
          width: auto;
          a {
            position: relative;
            width: 8em;
            padding-right: 25px;
            &::after {
              position: absolute;
              right: 8px;
            }
            &:hover {
              &::after {
                right: 0;
              }
            }
          }
        }
      }
    }

    .m3-custom-heading__link {
      background-color: unset;

      margin: 0;
      padding: 0 15px 0 0;

      vertical-align: middle;
      display: inline-block;
      text-align: center;

      a {
        margin: 0;
        padding: 17px 0;

        border: none;

        font-weight: 700;
        color: $v3_rhs_category_title_color;
        font-size: 11px;
        line-height: 12px;

        text-decoration: none;

        vertical-align: middle;
        display: inline-block;
        text-align: center;
        &:hover {
          color: $v3_rhs_category_title_color_hover;
        }
      }
    }
  }

  // ヘッダーのサブタイトル
  .atlas-rhs__panel__header-subtitle {
    background-color: unset;
    font-size: 14px;
    font-weight: 500;
    color: $v3_rhs_black_color;
  }

  // ----- rhs article list (small) -----
  .atlas-rhs__article-list--small {
    .atlas-rhs__article-list__img {
      width: 60px;
      border-radius: 4px;
      position: relative;
    }
  }

  .atlas-rhs__article-list {
    width: 100%;

    li {
      border-top: 1px solid #eeeff2;
    }
    .atlas-rhs__article-list__unit {
      width: calc(100% - 20px);
      background-color: unset; // 記事ごとに背景色は変える機能は削除になった
      padding-left: 10px;
      padding-right: 10px;
      border-top: 1px solid #eeeff2;
    }

    &.atlas-rhs__article-list--small {
      .atlas-rhs__article-list__img {
        position: relative;
      }
    }

    // ホバー時は背景色を変える
    .atlas-rhs__article-list__unit:hover {
      background-color: #FAFDFF;
      .atlas-rhs__article-list__title {
        text-decoration: none !important;
      }
    }

    // サムネイル写真
    .atlas-rhs__article-list__img {
      border-radius: 4px;
    }
    .atlas-rhs__article-list__img__thumbnail {
      display: block;
      width: 60px;
      height: 60px;
      border: 1px solid #eeeff2;
      border-radius: 4px;
      box-sizing: border-box;
      overflow: hidden;
      img {
        width: 58px;
        height: 58px;
        transition: transform .4s ease;
      }
    }

    // 写真左の青ポッチ部分（デフォルトは透明）
    @keyframes blink {
      0% {opacity: 0;}
      9% {opacity: 0;}
      10% {opacity: 1;transform:scale(0.5);}
      25% {transform:scale(0.5);}
      55% {transform:scale(1.2);}
      75% {transform:scale(1);}
    }
    .article-status-dot {
      float: left;

      margin-top: 15px;
      margin-left: 0;
      margin-right: 10px;

      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: none;
    }
    // 写真左の青ポッチ
    .article-status-dot.blue {
      position: absolute;
      top: -6px;
      left: -6px;
      z-index: 1;
      background-color: $v3_rhs_light_blue_color;
      border: 2px solid #fff;
      margin: 0;
      opacity: 0;
      &.active {
        animation: blink 1.5s;
        opacity: 1;
      }
    }

    // 写真
    .atlas-rhs__frame,
    .atlas-rhs__frame-mr {
      border: none;
    }
    .atlas-rhs__article-list__title {
      display: block; // 記事タイトルの横にラベル等を出さない
      font-size: 13px;
      font-feature-settings: "palt";
      color: $v3_rhs_list_title_color;
      .m3-text--action-point {
        margin-left: 4px;
      }
    }
    .atlas-rhs__article-list__text {
      margin-left: 70px;
      overflow: hidden; // 画像の下に記事テキストが回り込まないように

      .m3-text--action-point {
        color: $v3_rhs_red_color;
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
        & + i,i {
          margin: 0 0.15em;
        }
        span {
          color: $v3_rhs_black_color;
        }
      }
    }
    .m3-label--before {
      color: $v3_rhs_red_color; // 未読、NEWなどのステータスラベル
    }
    .m3-label--after {
      color: $v3_rhs_light_blue_color; // 未登録、未回答などのステータスラベル
    }

    // 記事テキスト
    .atlas-rhs__article-list__text {
      font-size: 14px;
      font-weight: 300;
      color: $v3_rhs_black_color;
      //margin-left: 52px;

      // ランキング 人気BEST1
      .atlas-rhs__article-list__ranking-best1__txt {
        padding: 0;
        float: unset;
        vertical-align: baseline;
        color: $v3_rhs_gold_color;
      }
      .atlas-rhs__article-list__ranking-best2__txt {
        padding: 0;
        float: unset;
        vertical-align: baseline;
        color: $v3_rhs_silver_color;
      }
      .atlas-rhs__article-list__ranking-best3__txt {
        padding: 0;
        float: unset;
        vertical-align: baseline;
        color: $v3_rhs_bronze_color;
      }

      .atlas-rhs__article-list__source {
        color: $v3_rhs_gray_color;
        font-size: 12px;
        font-weight: 300;
        white-space: pre-wrap; // 「my MR君」などの途中で
        margin-top: 4px;
      }
    }
  }

  // パネル最下部の注記
  .atlas-rhs__note {
    border: none;
    background: rgb(238, 239, 242);
    padding: 16px;
    font-size: 12px;
    font-weight: 300;

    a.atlas-rhs__note-link {
      display: inline-flex;
      align-items: baseline;

      margin-top: 4px;
      color: #40509E !important;
      line-height: 175%;
    }
    // リンクテキスト右の>記号の画像
    a.atlas-rhs__note-link::after {
      content: "";
      background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/glyphs/arrow-right.svg);
      background-size: contain;
      width: 11px;
      height: 11px;
      margin-left: 4px;
    }
  }

  // RHS最下部の注記
  .atlas-rhs__note.bottom {
    background: unset;
  }
}

// MR君や提携企業トピックスのパネルで使っているタブのエリアは、謎のpaddingやmarginが入っているのでそれを修正する
.v3.atlas-rhs .atlas-rhs__panel {
  // タブがある記事パネル
  .atlas-rhs__article-tab {
    background-color: unset;
    padding: 0;
    .m3-twbs-tab-content {
      margin: 0;
    }
  }
}

.v3.atlas-rhs {

  // MR君、QOL君
  .atlas-rhs__article-list__unit {
    position:relative;
    cursor:pointer;
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
    &:hover {
      background-color:#fafdff;
      .atlas-rhs__article-list__title {
        color: #6f737a;
        text-decoration: none;
      }
    }
  }
}

// 提携企業トピックス
.v3.atlas-rhs .atlas-rhs__panel {

  // タブがある記事パネル
  #wide-sponsors-tab.m3-twbs-nav-tabs {
    font-size: 12px;
    font-weight: 400;
    color: $v3_rhs_gray_color;

    display: flex;
    justify-content: space-between;

    list-style-type: none;

    li {
      flex: 1 1 auto;
    }
    // タブのデフォルト設定
    li a {
      font-size: 14px;
      font-weight: 600;
      color: $v3_rhs_gray_color;

      width: auto;

      display: block;
      text-align: center;

      border: unset;
      border-top: unset;
      background-color: unset;
    }

    // 選択されたタブ
    li.active a {
      font-weight: 700;
      color: #151B9E;
      border-bottom: 3px solid #151B9E;
    }
  }

  #sponsors-tab-content {
    .atlas-rhs__article-list--ranking {
      margin: 0;
      padding: 0;
    }

    .atlas-rhs__article-list {
      li {
        padding: 0px;
        width: auto;

        > a {
          display: block;
          color: $v3_rhs_black_color;
          font-size: 14px;
          font-weight: 600;
          line-height: 17.5px;
          padding: 16px;
          &:hover {
            background-color: #FAFDFF;
          }
        }
      }

      .atlas-rhs__article-list__source {
        margin-top: 5px;

        color: $v3_rhs_gray_color;
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
      }
    }

    .m3-article-list__item {
      color: $v3_rhs_black_color;
      font-size: 14px;
      font-weight: 600;
    }

    .atlas-rhs__article-list__source {
      color: $v3_rhs_gray_color;
      font-size: 12px;
      font-weight: 300;
      margin-top: 5px;

      .atlas-rhs__article-list__sponsor {
        color: $v3_rhs_gray_color;
      }

      .sponsors-label,.m3-status-label {
        background: unset;
        width: unset;
        font-size: 12px;
        padding: 0;
        vertical-align: baseline;
      }
      .sponsors-label.m3-category-label--bg-02 {
        color: $v3_rhs_red_color; // 緊急安全性情報(安全情報)
      }
      .sponsors-label.m3-category-label--bg-03 {
        color: rgb(75, 126, 8); // 新製品情報(新製品)
      }
      .sponsors-label.m3-category-label--bg-04 {
        color: rgb(219, 98, 16); // トピックス
      }
      .sponsors-label.m3-category-label--bg-05 {
        color: $v3_rhs_silver_color; // 添付文書改訂(添付文書)
      }
      .sponsors-label.m3-category-label--bg-09 {
        color: $v3_rhs_gold_color; // 医薬品のお知らせ(お知らせ)
      }
    }

    // ランキング
    .m3-article-list--ranking {
      > li:before {
        all: unset; // 既存の設定打消
      }

      > li {
        padding: 0;
        display: flex;
        align-items: center;
        &:first-child {
          border-top: 1px solid #e5e5e5;
        }

        > a {
          display: flex;
          align-items: center;
          line-height: 17.5px;
          color: $v3_rhs_black_color;
          padding: 14px 12px 12px 4px;
          &:hover {
            background-color: #FAFDFF;
          }
        }

        .atlas-rhs__article-list__img {
          margin: 0;
          flex: 0 0 auto;
        }

        .atlas-rhs__article-list__text {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          flex: 0 1 auto; // 記事部分が横にはみ出さないよう
          > a {
            color: $v3_rhs_black_color;
          }
        }
      }

      // 既存のランキング画像のやり方に合わせる
      [class*=ranking__item] {
        width: 50px;
        height: 52px;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
      }
      .ranking__item1 {
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/ranking/rhs_ranking_best1.svg);
      }
      .ranking__item2 {
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/ranking/rhs_ranking_best2.svg);
      }
      .ranking__item3 {
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/ranking/rhs_ranking_best3.svg);
      }
      .ranking__item4 {
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/ranking/rhs_ranking_4.svg);
      }
      .ranking__item5 {
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/ranking/rhs_ranking_5.svg);
      }
      .ranking__item6 {
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/ranking/rhs_ranking_6.svg);
      }
      .ranking__item7 {
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/ranking/rhs_ranking_7.svg);
      }
      .ranking__item8 {
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/ranking/rhs_ranking_8.svg);
      }
      .ranking__item9 {
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/ranking/rhs_ranking_9.svg);
      }
      .ranking__item10 {
        background-image: url(https://s.m3img.com/atlas/1.0.0/images/rhs/v3/ranking/rhs_ranking_10.svg);
      }
    }
  }
}

// Webcon
.v3.atlas-rhs #atlas-rhs_webcon.atlas-rhs__panel,
.v3.atlas-rhs #atlas-rhs_event.atlas-rhs__panel {
  .m3-status-label {
    display: inline-flex;
    align-items: center;
    background-color: #e3f4fc;//pls-sky/100
    color: #0e9fe7;//pls-sky/600
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    padding: 4px;
    border-radius: 2px;
    margin-bottom: 4px;
    &--now {
      background-color: #0e9fe7;//pls-sky/600
      color: #fff;
    }
    // ラベル内に入れるアイコン
    .atlas-rhs__article-list__icon {
      width: auto;
      max-height: 12px;
      margin-right: 4px;
    }
  }
  //ラベルの後の時間
  .atlas-rhs__article-list__time {
    display: inline-block;
    color: #0e9fe7;//pls-sky/600
    font-weight: 400;
    line-height: 1;
    vertical-align: 0.1em;
  }

  // パネル下部のリンク用のボタン
  .wlc-rhs-limited__link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px auto;

    width: min(100%, 223px);
    height: 42px;

    background: #40509E;
    box-shadow: 0px 4px #2A3468;
    border-radius: 4px;

    color: white;
    font-size: 15px;
    font-weight: 400;

    // リンクをボタンdiv全体に広げる設定
    position:relative;
    cursor:pointer;
  }

  // Web講演会 各パネル共通
  .wlc-rhs-limited {
    color: $v3_rhs_black_color;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;

    // 説明見出し
    dl {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      margin-left: 30px;
      margin-right: 10px;

      dt {
        margin: 0;
        padding: 0;

        // Web講演会アイコン画像
        img.pc-icon {
          width: 44px;
          height: 42px;
        }
      }

      dd {
        text-align: left;
        margin-left: 20px;

        color: $v3_rhs_black_color;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    // ※注記
    .note {
      color: $v3_rhs_gray_color;
      font-size: 10px;
      font-weight: 300;
      line-height: 24px;
    }
  }

  .wlc-rhs-limited--pressure {
    .use-images {
      display: flex;
      flex-wrap: wrap; // パネル幅が狭いときは縦に並べる

      margin: 10px auto;

      align-items: center;
      justify-content: center;

      img {
        width: 170px;
        height: 108px;
      }
    }
  }

  // ご視聴状況
  .wlc-rhs-history {
    display: flex;
    flex-wrap: wrap; // パネル幅が狭いときは縦に並べる

    dl {
      display: block;
      text-align: left;

      dt, dd {
        font-size: 12px;
        font-weight: 600;
        lien-height: 24px;
        color: $v3_rhs_gray_color;

        // 強調文字
        .bold {
          font-size: 24px;
          font-weight: 600;
          color: $v3_rhs_black_color;
        }

        // 薄い注記
        .note {
          font-size: 10px;
          font-weight: 300;
          line-height: 10px;
        }

        span, p {
          margin: 0;
          padding: 0;
        }
      }
    }

    .wlc-rhs-history__progress-area {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      width: 100%;

      dl.wlc-rhs-history__progress-text {
        width: 120px;
        margin: 0;
        padding: 0;

        dt, dd {
          margin: 0;
          margin-inline-start: unset;
          margin-top: 10px;
          padding: 0;
          width: 120px;
        }
      }

      .wlc-rhs-history__progress { // バー全体
        background: #EDF6FD;
        border-radius: 4px;
        margin: 0;
        margin-top: 10px;

        height: 24px;
        width: min(100%, 220px);

        .wlc-rhs-history__progress-bar { // 視聴済み分のバー
          border-radius: 4px;
          background: $v3_rhs_light_blue_color;
        }
      }
    }
    // 累積視聴公演数
    dl.accumulate-watch-count {
      width: 130px;
      margin: 10px 0;
      margin-right: 10px;

      dt {
        margin-bottom: 10px;
      }
      dd {
        margin: 0;
        padding: 0;
      }
    }
    // 前月の獲得アクション
    dl.wlc-rhs-history__contents--action {
      border: none;
      margin: 10px 0;

      dt {
        margin-bottom: 10px;
      }
      dd {
        display: unset; // 既存cssでなぜかnoneになっているので打ち消し
        margin: 0;
        padding: 0;
      }
    }
  }

  // 次の講演会までのカウントダウン
  dl.wlc-rhs-limited--countdown {
    text-align: left;

    dt {
      margin-top: 10px;

      color: $v3_rhs_gray_color;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }

    dd#countdownLabel {
      margin-top: 10px;

      text-align: left;

      // 時間、分、秒などの時刻単位
      color: $v3_rhs_gray_color;
      font-weight: 300;
      font-size: 12px;
      line-height: 30px;

      .hh, .mm, .ss {
        color: $v3_rhs_black_color;
        font-weight: 600;
        font-size: 24px;
        margin: 0;
      }

      .hour, .minute, .second {
        display: inline-block;
        margin-left: 0;
      }

      .hour, .minute {
        margin-right: 0.7em;
      }
    }
  }

  // ランキング
  .atlas-rhs__article-list__unit {
    display: flex;
    align-items: flex-start;

    .atlas-rhs__article-list__img {
      width: 50px;

      .webcon-list-normal {
        width: 45px;
        height: 60px;
        border: 1px solid #eeeff2;
        border-radius: 2px;
        transition: transform .4s ease;
      }
      img.webcon-list-ranking {
        width: 40px;
        height: 40px;
        border-radius: 2px;
      }
    }
    .atlas-rhs__article-list__text {
      margin: 0;
    }
  }
}
// Web講演会 CA
#atlas-rhs_event .atlas-rhs__article-list--large {
  & > li:hover {
    background-color: #FAFDFF;
    .atlas-rhs__article-list__text {
      color: #6f737a;
    }
  }
  .atlas-rhs__article-list__text {
    font-feature-settings: "palt";
  }
}
