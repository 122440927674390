.atlas-nav {
  min-height: 42px;
  border-bottom: 1px solid #d6dcee;
  padding-top: 5px;
  font-family: $base-font;
  font-size: $base-font-size;
  color: $base-font-color;
  background-color: #fff;
  position:relative;
  z-index: 6000;
}

@media screen and (min-width: 1280px) {
  .atlas-nav {
    min-height: 45px;
  }
}

.atlas-nav__main {
  width: 980px;
  margin: 0 auto;
}

@media screen and (min-width: 1280px) {
  .atlas-nav__main {
    width: 1200px;
  }
}

// ----- parent menu -----
.atlas-nav__parent {
  margin: 0;
  padding: 0;
  list-style-type: none;
  @include clearfix;
}

.atlas-nav__item {
  float: left;
  position: relative;
  margin-left: 3px;
}

.atlas-nav__item__link {
  display: block;
  border-bottom: 6px solid transparent;
  padding: 10px 10px 5px;
  position: relative;
  font-size: rem-calc(14);
  font-weight: bold;
  text-decoration: none;
  color: #555;
}

.atlas-nav__item__link--current {
  color: #768bc4;
  border-bottom: 6px solid #768bc4;
}

.pseudo-active {
  .atlas-nav__item__link {
    border-radius: 4px 4px 0 0;
    border-bottom-color: transparent;
    color: #fff;
    background-color: #4c59a8;
    @include box-shadow(rgba(0,0,0,0.3) 2px 2px 10px);
  }
}

@media screen and (min-width: 1280px) {
  .atlas-nav__item__link{
    padding: 10px 15px 5px;
    font-size: rem-calc(16);
  }
}

// ----- panel -----
.atlas-nav__panel {
  display: none;
  width: 618px;
  border: 1px solid #4c59a8;
  position: absolute;
  z-index: 6001;
  @include box-shadow(rgba(0,0,0,0.3) 2px 2px 10px);
  @include clearfix;
  background-color: #4c59a8;
}

// ----- child menu -----
.atlas-nav__child {
  width: 188px;
  min-height: 400px;
  margin: 0;
  padding: 0;
  float: left;
  list-style-type: none;
  background-color: #4c59a8;
}

.atlas-nav__subitem {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #6f79b8;

  .pseudo-active {
    background-color : #5c6bc1;
  }
}

.atlas-nav__subitem__link {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 8px 16px 8px 10px;
  position: relative;

  &:before {
    display: block;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -6px;
    font-size: 10px;
    font-family: "atlas-glyphs.20210326";
    content: "\f105";
  }
}

// ----- panel contents -----
.atlas-nav__content-group {
  width: 410px;
  padding: 10px 10px 40px 10px;
  float: left;
  background-color: #fff;

  a {
    color: $link-color;
    text-decoration: none;

    &:visited {
      color: $visited-color;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.atlas-nav__content {
  position: relative;
  min-height: 380px;
}

.atlas-nav__content-group {
  @include clearfix;
  width: 410px;
}

.atlas-nav__content__row {
  @include clearfix;
  width: 410px;
}

.atlas-nav__content__column {
  float: left;
  width: 200px;

  &:first-child {
    margin-right: 10px;
  }

  .m3-heading3 {
    margin-top: 0;
  }

  .m3-article-list__item {
    padding-top: 0;
    padding-bottom: 0;
  }
}


// ----- panel link to top -----
.atlas-nav__content__title {
  margin-bottom: 10px;
  font-size: rem-calc(14);
  font-weight: bold;
}


// ----- panel list style -----
.atlas-nav__panel-list {
  padding: 0;
  margin: 10px 0 0 0;
  list-style:none;

  li {
    @include clearfix;
    margin-top: 10px;
    line-height: 1.3
  }

  li:first-child {
    margin-top: 0;
  }
}

.atlas-nav__panel-list__thumb {
  text-align: center;

  a {
    display: block;
  }

  img {
    vertical-align: bottom;
  }
}

.atlas-nav__panel-list__thumb-w40 {
  float:left;
  width: 40px;
  margin-right:10px;
}

.atlas-nav__panel-list__thumb-w75 {
  float:left;
  width: 75px;
  margin-right:10px;
}

.atlas-nav__panel-list__thumb-w200 {
  width: 200px;
}

.atlas-nav__panel-list__ranking {
  float:left;
  margin-right:10px;
}

.atlas-nav__panel-list__title-area {
  overflow:auto;
  zoom:1;
  @include clearfix
}

.atlas-nav__panel-list__title {
  font-size: rem-calc(14);
  color: #495488;
  line-height: 1.2;
  vertical-align: middle;
  text-decoration: none;
  overflow: hidden;
}

.atlas-nav__panel-list__body {
  font-size: rem-calc(14);
  color:#666;
}

.atlas-nav__panel-list__caption {
  margin-top: 0;
  font-size: rem-calc(12);
  color:#666;
}

.m3-text--point {
  white-space: nowrap;
}

.m3-text--action-point {
  color:#d32222;
}

.m3-text--action-point-mark {
  vertical-align: text-top;
}

.m3-text--m3-point {
  color:#f60;
}

.m3-text--m3-point-mark {
  position: relative;
  top: 2px;
}

// ----- panel close button -----
.atlas-nav__close {
  position: absolute;
  right: 0;
  bottom: -32px;
  font-size: rem-calc(18);
  text-decoration: none !important;
}

.atlas-nav__panel-reverse {
  .atlas-nav__panel {
    position: absolute; right: 0
  }

  .atlas-nav__child {
    float: right
  }

  .atlas-nav__subitem__link {
    padding: 8px 8px 8px 18px;

    &:before {
      right: auto;
      left: 5px;
      content: "\f104";
    }
  }

  .atlas-nav__close {
    left: 0;
    right: auto;
  }
}


/* v3 メガメニュー */
.v3.atlas-nav {
  box-sizing: unset;
  .atlas-nav__menu * {
    box-sizing: border-box;
  }

  .atlas-nav__content {
    min-height: auto;
    @include clearfix;
    width: 960px;
    margin: 0 auto;
    padding: 0 10px;
    display: block;
    text-align: center;
    box-sizing: unset;
    @media screen and (min-width: 1280px) {
      width: 1260px;
    }
  }
}

.atlas-nav {
  $nav: &;

  min-height: 42px;
  border-bottom: 1px solid #d6dcee;
  padding-top: 5px;
  font-family: $base-font;
  font-size: $base-font-size;
  color: $base-font-color;
  background-color: #fff;
  position:relative;
  z-index: 6000;
  @media screen and (min-width: 1280px) {
    .atlas-nav {
      min-height: 45px;
    }
  }

  &__menu {
    $menu: &;
    display: inline-flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0;

    a {
      text-decoration: none;
      color: #3b3d42;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }

    &__item {
      display: inline-block;
      height: 35px;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      vertical-align: top;
      padding: 0;
      position: relative;
      &:not(:first-child) {
        margin-left: 12px;
        @media screen and (min-width: 1280px) {
          margin-left: 24px;
        }
      }
      &:hover {
        #{$menu}__drop {
          background-color: #3b46c4;
          border-radius: $base-border-radius;
          color: #fff;
        }
      }
      &:visited {
        #{$menu}__drop {
          color: #3b3d42;
        }
      }
      &:nth-last-child(-n+3) {
        #{$nav}__menu-sub {
          right: 0;
          left: auto;
        }
      }
    }

    &__drop {
      display: block;
      width: max-content;
      color: #3b3d42;
      text-decoration: none;
      padding: 8px 6px;
      transition: all 0.15s ease;
      transition-delay: 0.15s;
      &:hover {
        text-decoration: none;
      }
      &.active {
        background-color: #3b46c4;
        border-radius: $base-border-radius;
        text-decoration: none;
        color: #fff;
      }
    }
  }


  &__menu-sub {
    display: none;
    position: absolute;
    left: 0;
    top: 35px;
    z-index: 1;
    margin: 0;
    background-color: #fff;
    width: 280px;
    height: max-content;
    text-align: left;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 12px;

    &__item {
      list-style: none;
      width: 100%;
      &:not(:last-child) {
        border-bottom: 1px solid #eeeff2;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px 12px;
      .atlas-glyphs-arrow-right {
        font-size: 0.5625rem;
        margin: 0 0 0 auto;
        transition: all 0.3s ease;
      }
      &:hover {
        background-color: #f4f5f6;
        color: #6f737a;
        i {
          margin: 0 -10px 0 auto;
        }
      }
    }

    &__badge {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 1.75em;
      height: 1.75em;
      font-size: 0.625rem;
      background-color: #fceeed;
      color: #b51b1b;
      border-radius: 0.875rem;
      padding: 0 0.5em;
      margin-left: 4px;
      box-sizing: border-box;
    }
  }
}
