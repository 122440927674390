#atlas-side-menu {
  transform: translateX(-100%);
  font-family: $base-font;
  z-index: 8999;
  height: 100vh;
  width: 256px;
  position: fixed;
  overflow: auto;
  background: #FFFFFF;
  border-right: 1px solid #E5E7EB;
  display: flex;
  flex-direction: column;
  box-sizing: unset;
  text-align: left;

  &.side-menu-translateX {
    transform: translateX(0);
  }
  .side-menu__content {
    flex: 1;
    overflow-y: auto;
  }
  .side-menu__content::-webkit-scrollbar{
    width: 10px;
  }
  .side-menu__content::-webkit-scrollbar-track{
    background-color: #fff;
  }
  .side-menu__content::-webkit-scrollbar-thumb{
    background-color: #dcdfe4;
  }
  .category-list {
    padding-left: 16px;
  }
  .category {
    list-style: none;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 125%;
    color: #000066;
    .category-name {
      margin-bottom: 12px;
    }
  }
  .sub-category-list {
    padding-left: 0;
  }
  .sub-category {
    width: 216px;
    height: 34px;
    margin-bottom: 4px;
    list-style: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #3B3D42;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.1s;
    -webkit-transition: 0.1s;
    &:hover {
      background-color: #3b46c4;
      border-radius: $base-border-radius;
      opacity: unset;

      .sub-category-link {
        color: #fff;
        opacity: unset;
      }
    }
  }

  .sub-category-link {
    width: 100%;
    padding: 6px 0 6px 16px;
    text-decoration:none;
    color: #3B3D42;
  }
}

.side-menu-container {
  z-index: 8998;
}

.side-menu-background {
  z-index: 8998;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}

.side-menu-close-button {
  //background-image: url("../images/top/close-button.svg");
  //width: 28px;
  //height: 28px;
  //color: white;
  position: absolute;
  left: 280px;
  top: 24px;
  cursor: pointer;
/*
  img{
    width: 28px;
    height: 28px;
  }
  &:hover{
    transform: scale(1.1); 
  }
  */
  .close-circle {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 20px;
    margin: auto;
    position: relative;
    transition: all 0.1s;

    .line {
      height: 20px;
      width: 3px;
      border-radius: 0px;
      background-color: #666;
      position: absolute;
      transition: all 0.2s;
    }
    .one {
      top: 10px;
      left: 19px;
      transform: rotate(45deg);
    }
    .two {
      top: 10px;
      left: 19px;
      transform: rotate(-45deg);
    }
    &:hover{
      background-color: transparent;
      cursor: pointer;
      .one {
        height: 40px;
        top: 0px;
        transition: all 0.4s;
        background-color: #fff;
      }
      .two {
        height: 40px;
        top: 0px;
        transition: all 0.4s;
        background-color: #fff;
      }
    }
  }
}

.atlas-side-menu-nav__content {
  @include clearfix;
  width: 960px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  align-items: center;
  box-sizing: unset;
}

@media screen and (min-width: 1280px) {
  .atlas-side-menu-nav__content {
    width: 1180px;
  }
}

.atlas-side-menu-nav__parent {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  @include clearfix;
}

.atlas-side-menu-nav__item {
  height: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  float: left;
  position: relative;
  margin-left: 8px;
  list-style-type: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: #3b3d42;
  cursor: pointer;
  box-sizing: unset;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  a {
    color: #3b3d42;
  }

  &:hover {
    background-color: #3b46c4;
    border-radius: $base-border-radius;

    .atlas-side-menu-nav__item_link {
      color: #ffffff;
      opacity: unset;
    }
  }

  .atlas-side-menu-nav__item_link {
    padding: 8px 6px;
    text-decoration: none;
  }
}

@media screen and (min-width: 1280px) {
  .atlas-side-menu-nav__item {
    margin-left: 24px;
  }
}

.atlas-side-menu-nav__item_all {
  margin-left: 0;
  padding: 8px 6px;

  &:hover {
    background-color: #3b46c4;
    border-radius: $base-border-radius;
    color: #ffffff;
    opacity: unset;

    .hamburger-menu {
      filter: brightness(0) invert(1);
    }
  }

  .hamburger-menu {
    background-image: url("../images/top/hamburger-menu.svg");
    width: 12px;
    height: 10px;
    margin-right: 4px;
  }
}

.atlas-side-menu-opinion-box {
  background-color: #f7f7f7;
  border-radius: 4px;
  margin:16px;
  padding:16px;
  height: 19.5px;
  color: #3B3D42;
  cursor: pointer;
  box-sizing: unset;
  text-align: left;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  &:hover {
    background-color: #f0f9ff;
    height: 60.5px;
    .atlas-side-menu-opinion-box__container .atlas-side-menu-opinion-box__header::after {
      transform: rotate(225deg);
  }
    .atlas-side-menu-opinion-box__description{
      display: block;
      opacity: 1;
      margin-top:12px;
      transform: none;
    }
  }

  .atlas-side-menu-opinion-box__container {
    display:flex;
    align-items: center;

    .atlas-side-menu-opinion-box__icon {
      background-image: url("../images/top/opinion-box.svg");
      width: 16px;
      height: 14px;
    }

    .atlas-side-menu-opinion-box__header {
      display: flex;
      align-items: center;
      position: relative;
      font-weight: 600;
      cursor: pointer;
      font-size:13px;
      margin-left: 4px;
      &::-webkit-details-marker {
        display: none;
      }
      &::after {
        transform: translateY(-25%) rotate(45deg);
        width: 7px;
        height: 7px;
        margin-left: 10px;
        border-bottom: 1px solid #9EA2AA;
        border-right: 1px solid #9EA2AA;
        content: '';
        transition: transform .3s;
      }
    }
  }

  .atlas-side-menu-opinion-box__description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #6f737a;
    display: none;
  }
}

.side-menu-ca-area {
  background-color: #fff;
  padding: 0;
  width: 256px;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  > a {
    display: flex;
    padding: 16px;
    text-decoration: none;
    border-top: 1px solid #eeeff2;
  }

  .ca__service-info {
    display: block;
    margin-top: 4px;
    color: #9ea2aa;
    font-size: 10px;
  }

  .ca__service {
    flex: 1;
    width: 100%;
  }

  .ca__service-text {
    display: -webkit-box;
    line-height: 1.25;
    color: #3b3d42;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .ca__image {
    width: 50px !important;
    height: 50px !important;
    margin-right: 10px;
  }
  .ca__view_log {
    width: 0;
    height: 0;
  }
}
