body {
  min-width: 980px;
  margin: 0;
  padding: 0;
}

a > img {
  border: none;
}

// =================== BODY ===================
.atlas-body {
  background-color: #f6f6f6;
  min-height: 600px;
}

.body-content {
  width: 960px;
  margin: 0 auto;
  padding: 10px 10px 100px;
  @include clearfix;
}

// =================== MAIN ===================
.atlas-main {
  float: left;
  width: 720px;
  & > :first-child { margin-top: 0 !important }
}

// =================== Styles less than 1280px ===================
@media screen and (min-width: 1280px) {
  .body-content {
    width: 1180px;
  }

  .atlas-main {
    width: 780px;
  }
}
