@use "sass:math";

// Clearfix
// --------
@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

// shrink-strite-img スプライト画像サイズを高解像度向けに50%スケールダウン
// --------
@mixin shrink-sprite-img {
  *zoom: 0.5;
  -webkit-transform: scale(0.5);
     -moz-transform: scale(0.5);
      -ms-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: 0 0;
     -moz-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
  vertical-align: top;
}

// Box sizing
// --------
@mixin box-sizing($value:content-box) {
  -webkit-box-sizing: $value;
     -moz-box-sizing: $value;
          box-sizing: $value;
}

// Box shadow
// --------
@mixin box-shadow($value:none) {
  -webkit-box-shadow: $value;
     -moz-box-shadow: $value;
          box-shadow: $value;
}

// Text overflow
// --------
@mixin text-clamp($lines: 2, $line-height: false) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;

  @if $line-height {
    max-height: $line-height * $lines * 1px;
  }
}

// Transition
// --------
@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}

@mixin transition-property($property...) {
  -moz-transition-property:    $property;
  -o-transition-property:      $property;
  -webkit-transition-property: $property;
  transition-property:         $property;
}

@mixin transition-duration($duration...) {
  -moz-transition-duration: $duration;
  -o-transition-duration: $duration;
  -webkit-transition-duration: $duration;
  transition-duration: $duration;
}

@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function:    $timing;
  -o-transition-timing-function:      $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function:         $timing;
}

@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

// Transform
// --------
@mixin transform($transforms) {
  -moz-transform:    $transforms;
  -o-transform:      $transforms;
  -webkit-transform: $transforms;
  transform:         $transforms;
}

@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
   @include transform(scale($scale));
}

@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

// Gradient
// --------
@mixin gradient-horizontal($start-color: #fff, $end-color: #ccc, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent);
  background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent);
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1);
}

@mixin gradient-vertical($start-color: #fff, $end-color: #ccc, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0);
}

@mixin linear-gradient($top, $bottom){
    background: $bottom;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top), color-stop(100%, $bottom));
    background: -webkit-linear-gradient(top, $top 0%, $bottom 100%);
    background: -ms-linear-gradient(top, $top 0%, $bottom 100%);
    background: linear-gradient(to bottom, $top 0%, $bottom 100%);
}

// px to rem converter
// --------
 // The default font-size is set to 100% of the browser style sheet (usually 16px)
 // for compatibility with browser-based text zoom or user-set defaults.
$base-font-size: 100% !default;

 // $base-line-height is 24px while $base-font-size is 16px
 // $base-line-height: 150%;

 // This is the default html and body font-size for the base rem value.

 // Since the typical default browser font-size is 16px, that makes the calculation for grid size.
 // If you want your base font-size to be a different size and not have it effect grid size too,
 // set the value of $rem-base to $base-font-size ($rem-base: $base-font-size;)
$rem-base: 16px !default;

 // It strips the unit of measure and returns it
@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

 // Converts "px" to "rem" using the ($)rem-base
@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: math.div(strip-unit($value), strip-unit($base-value)) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

 // Working in ems is annoying. Think in pixels by using this handy function, rem-calc(#)
 // Just enter the number, no need to mention "px"
@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values); // Get the total number of parameters passed

  // If there is only 1 parameter, then return it as an integer.
  // This is done because a list can't be multiplied or divided even if it contains a single value
  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

  $remValues: (); // This will eventually store the converted $values in a list
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

 //Retaining this for backward compatability
@function remCalc($pxWidth) {
  @return math.div($pxWidth, $rem-base) * 1rem;
}


// Dropdown Animation
// --------
@mixin dropdown-animation-hidden {
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transition: all 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: all 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  opacity: 0;
}

@mixin dropdown-animation-show {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
}
