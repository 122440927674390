@charset "utf-8";
/* ================================================================ */
/* _wlc_rhs_limited.scss - WLC RHS Limited */
/* ================================================================ */
.wlc-rhs-limited {
  width: 96%;
  margin: 0;
  padding: 10px 2%;
  font-size: 0.875rem;
  background-color: #ffffff;
}

.wlc-rhs-limited__link {
  line-height: 1.2;
  margin: 0 2px;
  padding-top: 5px;
  font-size: 0.8125rem;
  text-align: center;
}

.wlc-rhs-limited__link a i {
  vertical-align: -1px;
  font-size: 1rem;
}

.wlc-rhs-limited__link a span {
  display: inline-block;
}

.wlc-rhs-limited__link a:hover span {
  text-decoration: underline;
}

@media screen and (min-width: 1280px){
  .wlc-rhs-limited__link {
    text-align: right;
  }
}

/* Lecture history ---------------------------------------------- */
.wlc-rhs-history {
  color: #6b8594;
  text-align: center;
}

.wlc-rhs-history__progress-area {
  margin: 0 0 12px;
  padding: 0 10px;
}

.wlc-rhs-history__progress-text {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  line-height: 1.4;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.wlc-rhs-history__progress-text dt,
.wlc-rhs-history__progress-text dd {
  margin: 0;
}

.wlc-rhs-history__progress-text dt {
  font-weight: bold;
  font-size: 1rem;
}

.wlc-rhs-history__progress-text dd {
  font-size: 0.6875rem;
}

.wlc-rhs-history__progress-text dd strong {
  vertical-align: -2px;
  margin-right: 2px;
  font-weight: normal;
  font-size: 1.875rem;
}

.wlc-rhs-history__progress-text dd span {
  vertical-align: 2px;
  margin: 0 3px;
  font-size: 1.25rem;
}

.wlc-rhs-history__progress {
  position: relative;
  display: flex;
  height: 12px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  border-radius: 20px;
  background-color: #dadada;
}

.wlc-rhs-history__progress-bar {
  border-radius: 20px;
  background-color: #6b8594;
  animation: graphAnim 1s forwards;
}
@keyframes graphAnim {
  0% {
    width: 0;
  }
}

.wlc-rhs-history__contents {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  line-height: 1.2;
  box-sizing: border-box;
  margin: 0 2px;
  padding: 4px;
}

.wlc-rhs-history__contents dt,
.wlc-rhs-history__contents dd {
  margin: 0;
}

.wlc-rhs-history__contents dt {
  text-align: left;
}

.wlc-rhs-history__contents dd {
  font-size: 1.875rem;
}

.wlc-rhs-history__contents dt span,
.wlc-rhs-history__contents dd span {
  font-size: 0.6875rem;
}

.wlc-rhs-history__contents dd span {
  vertical-align: 2px;
  margin-left: 2px;
}

.wlc-rhs-history__contents--action {
  border: 1px solid #8ba2af;
  border-radius: 5px;
}

.wlc-rhs-history__contents--action dt p {
  display: none;
  margin: 0;
  font-size: 0.65rem;
}

.wlc-rhs-history__contents--action dd:last-child {
  font-size: 0.625rem;
}

.wlc-rhs-history__contents--action dd img {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

@media screen and (min-width: 1280px){
  .wlc-rhs-history__progress-text {
    flex-flow: row;
  }
  .wlc-rhs-history__contents {
    flex-flow: row;
    padding: 4px 10px;
  }
  .wlc-rhs-history__contents--action {
    line-height: 1.5;
  }
  .wlc-rhs-history__contents--action dt p {
    display: block;
  }
  .wlc-rhs-history__contents--action dd:last-child {
    display: none;
  }
}

/* Rnking ------------------------------------------------------- */
.wlc-rhs-limited--ranking {
  line-height: 1.2;
  padding: 8px 2% 0;
  color: #81713a;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.wlc-rhs-limited--ranking strong {
  display: inline-block;
  margin-left: 5px;
  font-size: 1.125rem;
}

.wlc-rhs-limited--ranking span {
  margin-left: 5px;
  font-size: 1.5rem;
}

/* Count Down --------------------------------------------------- */
.wlc-rhs-limited--countdown {
  line-height: 1.2;
  padding: 10px 2% 5px;
  text-align: center;
}

.wlc-rhs-limited--countdown dt,
.wlc-rhs-limited--countdown dd {
  margin: 0;
}

.wlc-rhs-limited--countdown dd {
  font-size: 1.75rem;
}

.wlc-rhs-limited--countdown dd span {
  margin: 0 5px 0 3px;
  font-size: 1.25rem;
}

.wlc-rhs-limited--countdown dd span:last-of-type {
  margin: 0 0 0 3px;
}

/* Merit Appeal ------------------------------------------------- */
.wlc-rhs-limited--merit {

}
.wlc-rhs-limited--merit dl {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
}

.wlc-rhs-limited--merit dt,
.wlc-rhs-limited--merit dd {
  margin: 0;
}

.wlc-rhs-limited--merit dt {
  padding-right: 10px;
  text-align: left;
}

.wlc-rhs-limited--merit dt img {
  vertical-align: bottom;
}

.wlc-rhs-limited--merit ul {
  line-height: 1.2;
  margin: 0;
  padding: 0 12px 0 24px;
}

.wlc-rhs-limited--merit li {
  margin: 0;
  padding: 5px 0;
}

.wlc-rhs-limited--merit li span {
  display: block;
  font-size: 0.6875rem;
}

/* Peer Pressure ------------------------------------------------ */
.wlc-rhs-limited--pressure {
  width: 100%;
  padding: 10px 0;
}

.wlc-rhs-limited--pressure p {
  width: 96%;
  line-height: 1.2;
  margin: 0;
  padding: 0 2%;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.wlc-rhs-limited--pressure p span {
  display: inline-block;
}

.wlc-rhs-limited--pressure ul {
  display: flex;
  flex-flow: column;
  align-items: center;
  box-sizing: border-box;
  margin: 5px 0;
  padding: 0;
  list-style: none;
}

.wlc-rhs-limited--pressure li {
  width: 189px;
  padding-top: 10px;
  text-align: center;
}

@media screen and (min-width: 1280px){
  .wlc-rhs-limited--pressure ul {
    flex-flow: row;
  }
}
