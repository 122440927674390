.atlas-footer {
  font-family: $base-font ;
  font-size: $base-font-size;
  color: $base-font-color;
  background-color: #e5e5e5;
  border-top: 2px solid #778cc6;
  padding: 20px 10px;
}

.atlas-footer__copyright {
  margin: 0;
  padding: 0;
  font-size: rem-calc(12);
  color: #666666;
  float: left;
}

.atlas-footer__nav {
  margin: 0 0 0 -18px;
  padding: 0;
  float: right;
  list-style-type: none;

  li {
    margin: 0 0 0 18px;
    padding: 0;
    float: left;
    font-size: rem-calc(12);
  }

  a {
    text-decoration: none;
    color: $link-color;

    &:visited {
      color: $visited-color;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.atlas-footer__content {
  @include clearfix;
  width: 960px;
  margin: 0 auto;
  padding: 0;
}

@media screen and (min-width: 1280px) {
  .atlas-footer__content {
    width: 1180px;
  }
}

/////// フッター(v3)
// 既存のフッターCSSに影響を与えないため、v3クラスを指定する。
// また、v3クラス以下のものが既存のCSSからの影響を受けないようにするため、適宜 unset する。

// 既存のCSSの打ち消し
// --------
@mixin atlas-footer-basic-style-unset {
  float: unset;
  margin: unset;
  padding: unset;
  border: unset;
  height: unset;
  width: unset;
  background-color: unset;
  font-family: unset;
  font-size: unset;
  color: unset;
  text-align: unset;
  text-decoration: unset;
  list-style-type: unset;
}

.v3.atlas-footer {
  @include atlas-footer-basic-style-unset;

  min-width: min-content;
  background-color: #001240;
}

.v3.atlas-footer .atlas-footer__apps {
  width: 960px; // 既存デザインに合わせる
  margin: 0 auto;
  padding: 80px 0;
  transition: 0.1s;
  -webkit-transition: 0.1s;

  .apps__heading {
    @include atlas-footer-basic-style-unset;

    font-size: 16px;
    color: #fff;
    font-weight: bold;
    text-align: left;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding-left: 0;
  }

  li {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 16px;
    list-style: none;
    &:hover{
      background-color: rgba(255, 255, 255, 0.15);
    }

    a {
      color: #fff;
      text-decoration: none;
      display: grid;

      .apps__thumbs {
        line-height: 0;
        grid-row: 1 / 2;
        grid-column: 1 / 2;

        img {
          width: 64px;
        }
      }

      .apps__text {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        padding: 0 0 0 12px;
        box-sizing: border-box;
        margin: 0;
        font-size: 12px;

        p.apps__title {
          @include atlas-footer-basic-style-unset;

          font-size: 14px;
          color: rgba(255, 255, 255, 1);
          font-weight: bold;
          padding: 0;
          margin: 0;
          margin-bottom: 4px;
          text-align: left;
        }

        p {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          font-weight: normal;
          padding: 0;
          margin: 0;
        }

      }
    }
  }
}

.v3.atlas-footer .atlas-footer__bottom {
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}

// Clearfix の打ち消し
// --------
@mixin clearfix-none {
  *zoom: unset;
  &:before {
    display: none;
    content: none;
  }
  &:after {
    display: none;
    content: none;
  }
}

.v3.atlas-footer .atlas-footer__content {
  @include atlas-footer-basic-style-unset;
  @include clearfix-none;

  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  width: 960px; // 既存デザインに合わせる
  margin: 0 auto;

  p.atlas-footer__copyright {
    @include atlas-footer-basic-style-unset;

    color: #99A0B3;
    display: inline;
    float: left;
  }

  ul.atlas-footer__nav {
    @include atlas-footer-basic-style-unset;

    display: inline;
    padding-left: 0;

    li {
      @include atlas-footer-basic-style-unset;

      display: inline;
      list-style: none;

      a {
        @include atlas-footer-basic-style-unset;

        color: #99A0B3;
        text-decoration: none;
        margin-right: 16px;

        &:visited {
          color: #99A0B3;
          text-decoration: none;
        }

        &:hover {
          color: #99A0B3;
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .v3.atlas-footer .atlas-footer__apps {
    width: 1180px;
  }
}

@media screen and (min-width: 1280px) {
  .v3.atlas-footer .atlas-footer__content {
    width: 1180px;
  }
}
