.atlas-header__searchbox {
  position: absolute;
  right: -10px;
  top: 40px;
  z-index: 8002; //ヘッダーパーツとして調整
  padding: 0 10px;
  width: 330px;
  @include dropdown-animation-hidden;
  background-color: #40509e;
  @include box-shadow(rgba(0,0,0,0.3) 2px 2px 10px);
  @include box-sizing(border-box);
  font-size: $base-font-size;

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 6px;
    top: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 9px 7.5px;
    border-color: transparent transparent #40509e transparent;
  }
}

.m3-twbs-open {
  & > .atlas-header__searchbox {
    @include dropdown-animation-show;
  }
}

.atlas-header__search__area {
  margin: 10px 0;
}

.atlas-header__search__textbox {
  height: 35px;
  border: none;
  font-size: $base-font-size;
}

.atlas-header__search__button {
  top: 0;
  right: 0;
  padding: 8px 0 9px;
  background: #e9e9e9;
  width: 40px;
  height: 35px;
  margin: 0; // MEMO(ryoga-nagayama@m3.com): 開業・経営のCSSがお行儀悪くbuttonにmarginを設定してくるので対策
  @include box-sizing(border-box);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-top-right: 4px;
  -moz-border-radius-bottom-right: 4px;
  color: #40509e;
  font-size: $base-font-size;
}
