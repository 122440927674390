#m3_config {
  @include clearfix;
}

.atlas-header {
  line-height: 1.4;
}

#m3_main-body,
#m3_main-body .m3_wrapper,
#m3_topic-path
 {
  background: none !important;
}

#m3_footer {
  padding: 0 !important;
}

#m3_footer:after {
  content: none !important;
}

.m3_reward img {
	vertical-align: middle;
}