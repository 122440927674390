// Based on https://github.com/sapegin/grunt-webfont/blob/master/tasks/templates/bootstrap.css

@font-face {
  font-family: "atlas-glyphs.20210326";
  src: url("../fonts/atlas-glyphs.20210326.eot?_=1743582852982");
  src: url("../fonts/atlas-glyphs.20210326.eot?_=1743582852982#iefix") format("embedded-opentype"),
    //url("../fonts/atlas-glyphs.20210326.woff2?_=1743582852982") format("woff2"),
    url("../fonts/atlas-glyphs.20210326.woff?_=1743582852982") format("woff"),
    url("../fonts/atlas-glyphs.20210326.ttf?_=1743582852982") format("truetype");
    //url("../fonts/atlas-glyphs.20210326.svg?_=1743582852982#atlas-glyphs.20210326") format("svg")
  font-weight: normal;
  font-style: normal;
}

[class^="atlas-glyphs-"]:before,
[class*=" atlas-glyphs-"]:before {
  font-family: "atlas-glyphs.20210326";
  display: inline-block;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.atlas-glyphs-action:before {
  content: "\f101";
}

.atlas-glyphs-archive:before {
  content: "\f102";
}

.atlas-glyphs-arrow-down:before {
  content: "\f103";
}

.atlas-glyphs-arrow-left:before {
  content: "\f104";
}

.atlas-glyphs-arrow-right:before {
  content: "\f105";
}

.atlas-glyphs-arrow-up:before {
  content: "\f106";
}

.atlas-glyphs-attachment:before {
  content: "\f107";
}

.atlas-glyphs-b-check:before {
  content: "\f108";
}

.atlas-glyphs-batsu:before {
  content: "\f109";
}

.atlas-glyphs-blocked:before {
  content: "\f10a";
}

.atlas-glyphs-calender:before {
  content: "\f10b";
}

.atlas-glyphs-camera:before {
  content: "\f10c";
}

.atlas-glyphs-community:before {
  content: "\f10d";
}

.atlas-glyphs-double-arrow-down:before {
  content: "\f10e";
}

.atlas-glyphs-double-arrow-left:before {
  content: "\f10f";
}

.atlas-glyphs-double-arrow-right:before {
  content: "\f110";
}

.atlas-glyphs-double-arrow-up:before {
  content: "\f111";
}

.atlas-glyphs-external-link:before {
  content: "\f112";
}

.atlas-glyphs-eye:before {
  content: "\f113";
}

.atlas-glyphs-file-doc:before {
  content: "\f114";
}

.atlas-glyphs-file-pdf:before {
  content: "\f115";
}

.atlas-glyphs-file-ppt:before {
  content: "\f116";
}

.atlas-glyphs-file-xls:before {
  content: "\f117";
}

.atlas-glyphs-like:before {
  content: "\f118";
}

.atlas-glyphs-list:before {
  content: "\f119";
}

.atlas-glyphs-loading:before {
  content: "\f11a";
}

.atlas-glyphs-m-arrow-down:before {
  content: "\f11b";
}

.atlas-glyphs-m-arrow-left:before {
  content: "\f11c";
}

.atlas-glyphs-m-arrow-right:before {
  content: "\f11d";
}

.atlas-glyphs-m-arrow-up:before {
  content: "\f11e";
}

.atlas-glyphs-m-check:before {
  content: "\f11f";
}

.atlas-glyphs-mail-read:before {
  content: "\f120";
}

.atlas-glyphs-mail:before {
  content: "\f121";
}

.atlas-glyphs-maru:before {
  content: "\f122";
}

.atlas-glyphs-minus:before {
  content: "\f123";
}

.atlas-glyphs-page-left:before {
  content: "\f124";
}

.atlas-glyphs-page-right:before {
  content: "\f125";
}

.atlas-glyphs-pc:before {
  content: "\f126";
}

.atlas-glyphs-pencil:before {
  content: "\f127";
}

.atlas-glyphs-person:before {
  content: "\f128";
}

.atlas-glyphs-plus:before {
  content: "\f129";
}

.atlas-glyphs-print:before {
  content: "\f12a";
}

.atlas-glyphs-reply:before {
  content: "\f12b";
}

.atlas-glyphs-rod-b-arrow-down:before {
  content: "\f12c";
}

.atlas-glyphs-rod-b-arrow-left:before {
  content: "\f12d";
}

.atlas-glyphs-rod-b-arrow-right:before {
  content: "\f12e";
}

.atlas-glyphs-rod-b-arrow-up:before {
  content: "\f12f";
}

.atlas-glyphs-rod-m-arrow-down:before {
  content: "\f130";
}

.atlas-glyphs-rod-m-arrow-left:before {
  content: "\f131";
}

.atlas-glyphs-rod-m-arrow-right:before {
  content: "\f132";
}

.atlas-glyphs-rod-m-arrow-up:before {
  content: "\f133";
}

.atlas-glyphs-search:before {
  content: "\f134";
}

.atlas-glyphs-service-campaign:before {
  content: "\f135";
}

.atlas-glyphs-service-clinical:before {
  content: "\f136";
}

.atlas-glyphs-service-conference:before {
  content: "\f137";
}

.atlas-glyphs-service-detail:before {
  content: "\f138";
}

.atlas-glyphs-service-edetail:before {
  content: "\f139";
}

.atlas-glyphs-service-mrkun:before {
  content: "\f13a";
}

.atlas-glyphs-service-pcareer:before {
  content: "\f13b";
}

.atlas-glyphs-service-quiz:before {
  content: "\f13c";
}

.atlas-glyphs-service-survey:before {
  content: "\f13d";
}

.atlas-glyphs-service-todo:before {
  content: "\f13e";
}

.atlas-glyphs-service-web:before {
  content: "\f13f";
}

.atlas-glyphs-setting:before {
  content: "\f140";
}

.atlas-glyphs-speaker-off:before {
  content: "\f141";
}

.atlas-glyphs-speaker-on:before {
  content: "\f142";
}

.atlas-glyphs-star-blank:before {
  content: "\f143";
}

.atlas-glyphs-star:before {
  content: "\f144";
}

.atlas-glyphs-telephone:before {
  content: "\f145";
}

.atlas-glyphs-triangle-down:before {
  content: "\f146";
}

.atlas-glyphs-triangle-left:before {
  content: "\f147";
}

.atlas-glyphs-triangle-right:before {
  content: "\f148";
}

.atlas-glyphs-triangle-up:before {
  content: "\f149";
}

.atlas-glyphs-zoom-in:before {
  content: "\f14a";
}

.atlas-glyphs-zoom-out:before {
  content: "\f14b";
}
