.atlas-header {
  min-height: 40px;
  font-family: $base-font ;
  font-size: $base-font-size;
  color: $base-font-color;
  background-color: #006;

  a {
    color: #fff;
    text-decoration: none;
  }
}

.atlas-header__title {
  display: inline-block;
  margin: 0;
  max-width: 300px;
  max-height: 35px;
  overflow: hidden;
  float: left;
}

.atlas-header__logo,
.atlas-header__service-logo {
  float: left;
  margin: 7px 12px 0 0;
  padding: 0;
  height: 24px;
}
.atlas-header__service-logo--pcareer-scout {
  height: 19px;
}

.atlas-header__subtitle {
  margin-top: 10px;
  float: left;
  color: #fff;
  font-weight: normal;
  font-size: rem-calc(12);
}

.atlas-header__content {
  @include clearfix;
  width: 960px;
  margin: 0 auto;
  padding: 0 10px;
}

@media screen and (min-width: 1280px) {
  .atlas-header__content {
    width: 1180px;
  }
}

.atlas-header__accessory {
  @include clearfix;
  margin: 0;
  padding: 0;
  float: right;
  list-style: none;

  > li {
    @include clearfix;
    height: 40px;
    border-right: 1px solid #33337e;
    float: left;
  }
}

// ----- Header Icons & Badges -----
[class^="atlas-header__service-"] {
  position: relative;
  display: inline-block;
  padding: 8px 0 0;
  margin-right: 15px;
  &:last-child {
    margin-right: 25px
  }
}

.atlas-header__service {
  line-height: 1;
}

.atlas-header__icon {
  color: #8282B4;
  font-size: rem-calc(23);

  &.atlas-header__icon--highlight {
    color:#fff;
  }
}

.atlas-header__badge {
  display: block;
  position: absolute;
  top: 5px;
  left: 15px;
  z-index: 8001;
  padding: 1px 5px 0 5px;
  border-radius: 8px;
  float: left;
  font-size: rem-calc(10);
  line-height: $base-line-height;
  background-color: #cc0000;
  white-space: nowrap;
}


// ----- m3 Point -----
.atlas-header__point {
  margin: 0 10px;
  height: 40px;
}

.atlas-header__point__status {
  display: inline-block;
  color: #fff;
  margin: 10px 0;
}

.atlas-header__point__link {
  position: relative;
}

.atlas-header__point__mark {
  display: inline-block;
  @include sprite($mark_m3point);
  @include shrink-sprite-img;
  margin: 10px -14px 10px 10px;
}

// ----- Action -----
.atlas-header__action {
  margin: 0 10px;
  height: 40px;
}

.atlas-header__action__mark {
  display: inline-block;
  @include sprite($mark_m3action);
  @include shrink-sprite-img;
  margin: 11px -12px 12px -1px;
}

// ----- User -----
.atlas-header__user {
  color: #fff;
  position: relative;
  margin: 0 10px;
}

.atlas-header__name {
  display: block;
  position: relative;
  text-decoration: none;
  font-size: $base-font-size;
}

.atlas-header__username {
  display: inline-block;
  margin: 10px 3px 10px 0;
}

.atlas-header__notice {
  position: relative;
  display: inline-block;
}

.atlas-header__notice__arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 50%;
  left: -5px;
  margin-top: -4px;
  border-width: 4px 7px 4px 0;
  border-right-color: #c00;
}

.atlas-header__notice__inner {
  max-width: 30px;
  padding: 2px 7px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #C00;
  border-radius: 10px;
  font-size: rem-calc(12);
  font-weight: bold;
}

// ----- Search -----
.atlas-header__search {
  position: relative;
  margin: 0 10px;
  font-size: rem-calc(18);

  a {
    display: inline-block;
    padding: 7px 0 8px;
  }
}

// ----- Help -----
.atlas-header__help {
  float: right;
  margin: 10px 0 0 0;
}

.atlas-header__help a{
  padding: 0 8px;
}

/* === ActionTextbox(Common action+text) ======================== */

.atlas-header__actiontextbox {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 10px rgba(0,0,0,.2);box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 18px 30px;
  border-radius: 2px;
  background-color: #ffffff;
  color: #333333;
  font-weight: bold;
  font-size: 0.875rem;
  opacity: 0;
  white-space: nowrap;
  z-index: 9000;
}

.atlas-header__actiontextbox__text {animation: motion-text 2s 1;}
@keyframes motion-text {
  0%,100%   {top: 5px;opacity: 0;}
  10%       {opacity: 0.95;}
  20%       {top: 20px;}
  85%       {top: 20px;opacity: 0.95;}
}

/* -- action+text ----------------------------------------------- */
.atlas-header__actiontextbox p {
  margin:0 0 0 20px;
}

.atlas-header__actiontextbox__action {
  position: absolute;
  height: 15px;
  width: 15px;
}

.atlas-header__actiontextbox__action.atlas-header__actionbox__bound {animation: motion-actiontext 1.5s 1;}
@keyframes motion-actiontext {
  0%,50%,100% {transform: rotateY(0deg);margin-top: 0;}
  54%         {margin-top: 2px;}
  58%         {transform: rotateY(0deg);}
  60%         {margin-top: -11px;}
  75%         {transform: rotateY(200deg);}
  90%         {transform: rotateY(360deg);margin-top: -11px;}
}

/* === Actionbox(Common bound,pulse) ============================ */

.atlas-header__actionbox{
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 30px;
  width: 17px;
}

.atlas-header__actionbox__action {
  position: absolute;
  top: 0;
  margin: 11px 0 0 -1px;
  height: 15px;
  width: 15px;
}

/* -- bound ----------------------------------------------------- */
.atlas-header__actionbox__bound {animation: motion-bound 1.3s 1;}
@keyframes motion-bound {
  0%,100% {transform: rotateY(0deg);}
  20%     {transform: rotateY(0deg);margin-top: 12px;}
  35%     {transform: rotateY(200deg);margin-top: 0px;}
  50%     {transform: rotateY(360deg);}
}

/* -- pulse ----------------------------------------------------- */
.atlas-header__actionbox__pulse {
  border-radius: 50%;
  margin: 16px 0 0 4px;
  height: 5px;
  width: 5px;
  animation: motion-pulse 2s 1;
}
@keyframes motion-pulse {
  0%   {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
  5%   {box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);}
  90%  {box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);}
  100% {box-shadow: 0 0 0 0px rgba(255, 255, 255, 0);}
}

.atlas-header__actionbox__pulse + .atlas-header__actionbox__action{animation: motion-pulse__action 2s 1;}
@keyframes motion-pulse__action {
  0%  {transform: scale(0);}
  30% {transform: scale(1);}
}

/* === PointTextbox ============================================= */

.atlas-header__pointtextbox {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 10px rgba(0,0,0,.2);box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 18px 30px;
  border-radius: 2px;
  background-color: #ffffff;
  color: #333333;
  font-weight: bold;
  font-size: 0.875rem;
  opacity: 0;
  white-space: nowrap;
  z-index: 9000;
}

.atlas-header__pointtextbox__text {animation: motion-text 2s 1;}

/* === Pointbox ================================================= */

.atlas-header__pointbox{
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 30px;
  width: 27px;
}

.atlas-header__pointbox__point {
  position: absolute;
  right: 0;
  top: 0;
  margin: 11px 0 0 -1px;
  height: 16px;
  width: 16px;
}

/* -- bound ----------------------------------------------------- */
.atlas-header__pointbox__bound {animation: motion-bound 1.3s 1;}

/* === NoticeTextbox ============================================ */

.notice-textbox {
  position: fixed;
  line-height: 1.3;
  max-width: 292px;
  max-height: 40px;
  box-sizing: border-box;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 8px 10px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 0.8125rem;
  opacity: 0;
  z-index: 9000;
}

/* -- size ------------------------------------------------------ */
.notice-textbox--multiple-lines{
  max-width: 352px;
  max-height: 92px;
}

/* -- position -------------------------------------------------- */
.notice-textbox--rt {
  top: 100px;
  right: -1000px;
  animation: notice-textbox--rt 3s 1;
}
@keyframes notice-textbox--rt {
  0%,100%   {right: 5px;opacity: 0;}
  10%       {opacity: 0.95;}
  20%       {right: 20px;}
  85%       {right: 20px;opacity: 0.95;}
}

.notice-textbox--lb{
  left: -1000px;
  bottom: 60px;
  animation: notice-textbox--lb 3s 1;
}
@keyframes notice-textbox--lb {
  0%,100%   {left: 5px;opacity: 0;}
  10%       {opacity: 0.95;}
  20%       {left: 20px;}
  85%       {left: 20px;opacity: 0.95;}
}

.notice-textbox--ct{
  top: -1000px;
  left: 50%;
  transform: translateX(-50%);
  animation: notice-textbox--ct 3s 1;
}
@keyframes notice-textbox--ct {
  0%,100%   {top: 20px;opacity: 0;}
  10%       {opacity: 0.95;}
  90%       {opacity: 0.95;}
}

/* -- decoration ------------------------------------------------ */
.notice-textbox--bg-white {
  background-color: #ffffff;
  color: #333333;
}
.notice-textbox--bg-black {
  background-color: #333333;
  color: #ffffff;
}

/* -- dummy link ------------------------------------------------ */
a.notice-textbox--dummy-link.notice-textbox--bg-white,
a.notice-textbox--dummy-link.notice-textbox--bg-white:hover,
a.notice-textbox--dummy-link.notice-textbox--bg-white:visited {
  color: #333333;
  text-decoration: none;
  cursor: default;
}

a.notice-textbox--dummy-link.notice-textbox--bg-black,
a.notice-textbox--dummy-link.notice-textbox--bg-black:hover,
a.notice-textbox--dummy-link.notice-textbox--bg-black:visited {
  color: #ffffff;
  text-decoration: none;
  cursor: default;
}

/* --- cp・unread zero --- */
.cp-unread-zero.notice-textbox--bg-black {
  color:#fff;
  font-size:12px;
  .cp-unread-zero_number {
    font-size:14px;
  }
  .cp-unread-zero_btn {
    display:inline-block;
    margin-left:8px;
    padding:4px 8px 3px 8px;
    background:#f9982c;
    border-bottom:3px solid #d37206;
    border-radius:3px;
    color:#fff;
    text-align:center;
    &:hover {
      background-color: #faa445;
      border-bottom: 3px solid #eb7f07;
    }
  }
  &.notice-textbox--multiple-lines {
    text-align:center;
    .cp-unread-zero_number {
      font-size:16px;
    }
    .cp-unread-zero_btn {
      display:inline-block;
      margin-top:4px;
      padding:6px 20px;
      background:#f9982c;
      border-bottom:3px solid #d37206;
      border-radius:3px;
      color:#fff;
      text-align:center;
    }
  }
}
.notice-cumulative.notice-textbox {
  line-height:1;
  & img {
    padding:0 1px;
    vertical-align:-4px;
    &:nth-child(2) {
      padding:0 2px 0 1px;
      vertical-align:-1px;
    }
  }
}

/* animation */
.cp-unread-zero.notice-textbox--rt {
  animation: notice-textbox--rt 8s 1;
}
.cp-unread-zero.notice-textbox--lb {
  animation: notice-textbox--lb 8s 1;
}

/* hover */
a.cp-unread-zero {
  transition: 0.2s!important;
  cursor:pointer;
}
a.notice-textbox--bg-black.cp-unread-zero:hover {
  background-color: #404040;
  .cp-unread-zero_btn {
    background-color: #faa445;
    border-bottom: 3px solid #eb7f07;
  }
}

// v3　リニューアルヘッダー用 ------------------------------------------------------------

.v3.atlas-header {
  min-height: 45px;
  color: $v3-base-font-color;
  background-color: #FFFFFF;
  border-bottom: 1px solid $border-color;
  //padding-left: 0!important;

  a {
    color: $v3-base-font-color;
    font-weight: 400;
    transition: 0.1s;
    -webkit-transition: 0.1s;
  }
  .atlas-header__point a:hover,
  .atlas-header__action a:hover,
  .atlas-header__help a:hover,
  .m3-twbs-dropdown.atlas-header__name:hover{
    opacity:0.6;
  }
  .atlas-header__logo,
  .atlas-header__service-logo {
    margin: unset;
    height: 28px;
  }
  .atlas-header__service-logo--pcareer {
    height: 24px;
  }

  .atlas-header__content {
    height: 55px;
    display: flex;
    align-items: center;
    &:after {
      content: unset;
    }
    .atlas-header__help{
      margin: 0;
      margin-left: auto;
    }
    @media screen and (min-width: 1280px) {
      width: 1260px;
    }
  }
  .atlas-header__subtitle {
    margin-top: 6px;
    margin-left: 8px;
    color: #3b3d42;
    font-weight: normal;
     font-size: 0.75rem;
  }
}

/*
@media screen and (max-width: 1280px){
  .v3.atlas-header .atlas-header__content{
    width: 960px;
    background-color:green;
  }
  }
  @media screen and (min-width: 1280px){
  .v3.atlas-header .atlas-header__content{
      width: 1280px;
      background-color:blue;
    }
  }

  @media screen and (min-width: 1280px){
  .v3.atlas-side-menu-nav__content{
     width: 1280px !important;
    }
  }

  @media screen and (min-width: 1536px){
  .v3.atlas-header .atlas-header__content{
      width: 1536px;
      background-color:red;
    }
  }*/


.v3.atlas-header {

  .atlas-header__accessory {
    margin-left: auto;

    > li {
      @include clearfix;
      height: 45px;
      border-right: unset;
      display: flex;
      align-items: center;
      line-height: 0.5;
    }
  }

  .atlas-header__vertical_line {
    margin-left: 4px;
    width: 1px;
    height: 26px;
    background: $border-color;
  }
}

// ----- Header Icons & Badges -----
.v3.atlas-header [class^="atlas-header__service-"] {
  margin-right: 4px;
  padding: 0;

  &:last-child {
    margin-right: 25px
  }

  a {
    display: block;
    padding: 10px 8px;
    &:hover {
      .atlas-header__icon {
        color: #9ea2aa;
      }
    }
  }
}

.v3.atlas-header {

  .atlas-header {
    &__icon {
      color: $v3-base-font-color;
      font-size: rem-calc(19);
      transition: 0.1s;
      &:hover{
        color: #9ea2aa;
      }
    }

    &__badge {
      color: #FFFFFF;
      top: 5px;
      border-radius: 12px;
      background-color: #db1e1c;
      left: 22px;
    }

    &__point {
      height: 45px;
      margin: 0 10px 0 10px;
      &__status {
        color: $v3-base-font-color;
        margin: 20px 0;
      }
      &__mark {
        margin: 14px -18px 10px 10px;
      }
      &__amount__suffix {
        font-weight: 400;
        color: #6F737A;
        font-size: 12px;
        vertical-align: middle;
      }
    }

    &__action {
      margin: 0;
      height: 45px;
      &__mark {
        margin: 16px -12px 12px -1px;
      }
    }

    &__user {
      background: #F7F7F7;
      border: 1px solid $border-color;
      border-radius: 4px;
      height: 30px;
      padding: 0;
    }

    &__name {
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      &:hover{
        opacity:0.6;
      }
    }

    &__notice {
      &__arrow{
        left: -3px;
      }
      &__inner{
        padding: 2px 6px;
        font-size: 0.625rem;
        border-radius: 100%;
        font-weight: normal;
        vertical-align: 2px;
        margin-right: 2px;
        line-height: 0;
      }
    }

    &__username{
      margin: 10px 6px 10px 0;
      &__suffix {
        font-weight: 300;
        color: #6F737A;
      }
    }

    &__actionbox {
      margin: 5px 0;
    }

    &__crown {
      width: 20px;
      height: 17px;
      vertical-align: -2px;
    }

    // 検索窓
    &__search {
      display: none;
      @media screen and (min-width: 1280px){
        display: inline-flex;
      }
      * {
        box-sizing: border-box;
      }

      &__type {
        display: flex;
        padding: 0;
        margin: 0 0 0 16px;

        & > a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #FFF;
          min-width: calc(5em + 26px);
          padding: 0 8px 0 12px;
          font-size: 0.75rem;
          text-align: left;
          border: 1px solid $border-color;
          border-radius: 4px 0 0 4px;
          i {
            font-size: 0.5625rem;
            margin-left: 4px;
          }
        }
      }

      &__form-box {
        margin: 0;
        form {
          margin-block-end: auto;
        }
        button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          outline: none;
        }
      }
      &__form {
        padding: 0;
        margin: 0;
      }
      &__input {
        background: #f7f7f7;
        width: 140px;
        height: 34px;
        padding: 0 12px;
        border: none;
        margin: 0;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        font-size: 0.875rem;
        &:focus,
        &:focus-visible,
        &:active {
          outline: none;
        }
        &::placeholder {
          color: #ccc;
        }
      }
      &__button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: none;
        border: 1px solid $border-color;
        vertical-align : top;
        height: 34px;
        padding: 0;
        border-radius: 0 4px 4px 0;
        &:hover {
          cursor: pointer;
        }
      }
      &__icon {
        display: block;
        width: 14px;
      }
    }

    // サービスアイコン一覧部分
    &__service-search {
      &:hover {
        opacity: 0.6;
      }
      @media screen and (min-width: 1280px){
        display: none;
      }
    }
  }

  .notice-textbox {
    max-height: 45px;
  }
}

